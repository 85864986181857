<template>
  <div
    class="order-card"
    :style="{ borderColor: active ? '#5FA742' : '#7a7a7a' }"
    :class="{ fullState: fullState }"
  >
    <div class="ribbon" v-if="ribbon">
      <div
        class="yourOrder"
        :style="{ backgroundColor: active ? '#5FA742' : '#7a7a7a' }"
      >
        <div class="label">
          {{ $t('order.yourOrder') }}
        </div>
      </div>
      <div
        class="calendarEvent"
        @click="createCalendarEvent"
        v-if="showCalendarEvent && canAddToCalendar"
      >
        <md-icon>event</md-icon>
        <span class="label">{{ $t('order.createCalendarEvent') }}</span>
      </div>
    </div>
    <div class="content">
      <div class="service">
        <md-icon>sell</md-icon>
        <div class="info">
          <div class="label">{{ orderTitle }}</div>

          <div class="status" v-if="orderStatus != 'draft'">
            <span>{{ $t('order.status') }}: </span>
            <span class="value">
              {{ $t(`order.statusTypes.${orderStatus}`) }}
            </span>
          </div>
        </div>
      </div>

      <div v-if="fullState" class="qr">
        <hr />
        <p>{{ $t('order.qr') }}</p>
        <img :src="qr" />
        <p>{{ order.orderId.toUpperCase() }}</p>
        <hr />
      </div>

      <div class="items">
        <div
          v-for="item in order.orderItem.orderItems"
          :key="item.orderItemId"
          class="item"
          v-show="!compactState"
        >
          <div class="flex">
            <div class="content">
              <div class="info">
                <div class="name">{{ item.offerTitle }}</div>
              </div>
              <div class="attributes">
                <OrderAttributes
                  :attributes="item.attributeValues"
                  v-show="!compactState"
                />
              </div>
            </div>

            <div
              class="remove"
              @click="removeItem(item)"
              v-if="
                emptyState &&
                  !disableClearBtn &&
                  ['FOOD', 'FOOD2', 'COMMON_CART'].includes(order.serviceType)
              "
            >
              <md-icon>clear</md-icon>
            </div>
          </div>
          <hr class="dotted" />
        </div>
      </div>

      <div class="attributes">
        <OrderAttributes :attributes="attributeValues" v-show="!compactState" />
      </div>

      <LegalInfo :text="offer.legalInfo" :short="true" v-if="fullState" />

      <md-progress-bar md-mode="indeterminate" v-if="loading"></md-progress-bar>

      <div class="menu-out" v-if="showMenu" @click="showMenu = false"></div>
      <div class="menu" v-if="showMenu" :class="{ fullState: fullState }">
        <div
          class="item"
          v-for="(item, key) in menuItems"
          :key="key"
          @click="
            item.func()
            showMenu = false
          "
          v-show="item.if != undefined ? item.if : true"
        >
          {{ typeof item.label == 'function' ? item.label() : item.label }}
        </div>
      </div>
      <div
        class="menu-btn"
        v-if="showMenuBtn && compactState"
        @click="toggleMenu"
      >
        <md-icon>menu</md-icon>
      </div>
    </div>

    <div
      class="desk"
      :style="{ backgroundColor: active ? '#5FA742' : '#7a7a7a' }"
      :class="{ inversedRow: emptyState && hasPrice }"
      v-if="showDesk"
    >
      <div class="date" v-if="!emptyState">
        <md-icon>perm_contact_calendar</md-icon>
        <span>{{ orderDate }}</span>
      </div>

      <div class="price" v-if="hasPrice">
        {{
          emptyState
            ? $t('order.total', [order.priceTotal, order.priceCurrency])
            : `${order.priceTotal} ${order.priceCurrency}`
        }}
      </div>
    </div>
  </div>
</template>

<script>
import LegalInfo from '@/components/LegalInfo'
import OrderAttributes from '@/components/OrderAttributes'
import createCalendarEvent from '../lib/createCalendarEvent'

export default {
  props: [
    'srcOrder',
    'ribbon',
    'state',
    'loading',
    'disableClearBtn',
    'showCalendarEvent',
  ],
  components: { LegalInfo, OrderAttributes },
  data() {
    return {
      showMenu: false,
      menuItems: [],
      qr: '',
      offer: {},
      activeOrdersTypes: ['pending', 'in_progress'],
      order: {},
    }
  },
  created() {
    Object.assign(this.order, this.srcOrder)
    this.attributes = this.order.orderItem.attributeValues
  },
  mounted() {
    this.fetchQr()
    this.fetchOffer()
    this.updateMenuItems()
    this.updateOrder()
  },
  methods: {
    cancelOrder() {
      this.$api.delete(`user/orders/${this.order.orderId}`).then(() => {
        this.updateOrder()
      })
    },
    updateOrder(preloadOrder) {
      this.fetchQr()

      if (preloadOrder) {
        this.order = preloadOrder
        this.$nextTick(this.$forceUpdate)
      } else
        this.$api
          .get(`user/orders/${this.order.orderId}?availablePaymentMethods=true`)
          .then(res => {
            this.order = res.data.data
            this.$nextTick(this.$forceUpdate)
          })
    },
    removeItem(item) {
      this.order.orderItem.orderItems = this.order.orderItem.orderItems.filter(
        orderItem => orderItem != item
      )

      this.$api
        .post(`user/orders`, {
          orderId: this.order.orderId,
          action: 'UPDATE',
          orderItemRequests: [
            { action: 'REMOVE', orderItemId: item.orderItemId },
          ],
        })
        .then(() => {
          this.$api
            .post(`user/orders`, {
              action: 'UPDATE',
              orderId: this.order.orderId,
            })
            .then(res => {
              this.order = res.data.data
              this.$nextTick(this.$forceUpdate)
              this.updateHostOrder()
            })
        })
    },
    updateHostOrder() {
      this.$emit('orderUpdated')
    },
    fetchQr() {
      this.$api
        .get(`user/orders/${this.order.orderId}/qr`, { responseType: 'blob' })
        .then(res => {
          this.qr = URL.createObjectURL(res.data)
        })
    },
    fetchOffer() {
      this.$api.get(`offers/${this.order.orderItem.offerId}`).then(res => {
        this.offer = res.data.data
      })
    },
    updateMenuItems() {
      this.menuItems = [
        {
          label: this.$t('order.more'),
          if: this.compactState,
          func: () => {
            this.$router.push('/orderdetails/' + this.order.orderId)
          },
        },
        {
          label: this.$t('order.cancel'),
          if:
            this.order.cancelable &&
            !['DONE', 'IN_PROGRESS', 'PENDING_TO_CANCEL', 'CANCELED'].includes(
              this.order.status
            ),
          func: this.cancelOrder,
        },
      ]
    },
    toggleMenu() {
      this.showMenu = !this.showMenu
      if (!this.showMenu) this.updateOrder()
    },
    createCalendarEvent() {
      const slotsAttrValue = this.order.orderItem.attributeValues.find(
        attr => attr['@type'] == 'slots'
      )?.value

      const dateAttrValue = this.order.orderItem.attributeValues.find(
        attr => attr['@type'] == 'date_time'
      )?.value

      if (slotsAttrValue)
        return createCalendarEvent(
          this.order.orderItem.offerTitle,
          new Date(slotsAttrValue.startTime).normalize(true),
          new Date(slotsAttrValue.endTime).normalize(true)
        )

      if (dateAttrValue)
        return createCalendarEvent(
          this.order.orderItem.offerTitle,
          new Date(Number(dateAttrValue)).normalize(true)
        )
    },
  },
  computed: {
    orderDate() {
      let date = new Date()
      date.setTime(this.order.createdAt)
      date = date.normalize(true)
      let time = date
        .toTimeString()
        .split(' ')[0]
        .split(':')
      time.pop()
      return [date.toLocaleDateString(), time.join(':')].join(' ')
    },
    hasPrice() {
      return this.order.priceTotal > 0
    },
    showDesk() {
      return this.hasPrice || !this.emptyState
    },
    attributeValues() {
      return this.order.orderItem?.attributeValues
    },
    orderStatus() {
      return this.order.status.toLowerCase()
    },
    showMenuBtn() {
      let count = 0

      this.menuItems.forEach(item => {
        if (item.if || item.if == undefined) count++
      })

      return count > 0
    },
    orderTitle() {
      return (
        this.order.serviceTitle ??
        this.order.serviceName ??
        this.order.orderItem.offerTitle ??
        this.$t('order.order')
      )
    },
    active() {
      return this.activeOrdersTypes.includes(this.order.status.toLowerCase())
    },
    canAddToCalendar() {
      if (!this.order?.orderItem?.attributeValues) return false

      const slotsAttrValue = this.order.orderItem.attributeValues.find(
        attr => attr['@type'] == 'slots'
      )

      const dateAttrValue = this.order.orderItem.attributeValues.find(
        attr => attr['@type'] == 'date_time'
      )

      return Boolean(slotsAttrValue || dateAttrValue)
    },
    compactState() {
      return this.state == 'compact'
    },
    fullState() {
      return this.state == 'full'
    },
    emptyState() {
      return this.state == undefined
    },
  },
}
</script>

<style lang="scss">
.order-card {
  position: relative;
  margin: 30px 5px 10px 5px;
  border: 1px solid #a1a0a0;

  &.fullState {
    border: none;
    display: flex;
    flex-direction: column-reverse;
  }

  .ribbon {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: -30px;
    left: -1px;

    > .yourOrder {
      background-color: #7a7a7a;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      height: 30px;
      width: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      > .label {
        color: white;
        font-size: 16px;
      }
    }

    > .calendarEvent {
      cursor: pointer;
      display: flex;
      align-items: flex-start;
      gap: 3px;

      > .label {
        border-bottom: 1px solid #959595;
        font-size: 13px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
  > .content {
    position: relative;
    padding: 15px;
    padding-bottom: 0;

    .service {
      position: relative;
      margin-bottom: 15px;
      display: flex;

      .md-icon {
        margin: 0;
      }

      .info {
        margin-left: 20px;

        .label {
          font-size: 17px;
        }

        .status {
          .value {
            font-weight: 500;
            font-size: 15px;
            background-color: transparent;
            color: black;
          }
        }
      }
    }

    .qr {
      width: 80%;
      margin: 0 auto;
      text-align: center;
      color: #7a7a7a;
      font-size: 15px;

      img {
        width: 50%;
      }
      p {
        margin: 0;
      }
    }

    .menu-btn {
      position: absolute;
      right: 10px;
      top: 10px;
      transition: 0.2s all;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    .menu {
      position: absolute;
      right: 5px;
      top: 5px;
      background-color: white;
      box-shadow: 0 1px 11px 1px rgba(0, 0, 0, 0.08);
      z-index: 1;

      &.fullState {
        margin-top: -80px;
      }

      .item {
        width: 300px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        transition: 0.2s all;
        background-color: transparent;
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
    .menu-out {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    .order-date {
      position: relative;
      margin-top: 25px;
      margin-bottom: 25px;

      .date {
        margin-left: 20px;
        font-size: 15px;

        position: absolute;
        top: -10px;
      }
      .time {
        margin-left: 20px;
        font-size: 19px;

        position: absolute;
        top: 10px;
      }
    }

    .md-progress-bar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;

      .md-progress-bar-fill {
        background-color: rgb(122, 122, 122);

        &::after {
          background-color: rgb(122, 122, 122);
        }
      }
      .md-progress-bar-track {
        background-color: rgb(122, 122, 122);
      }
    }

    > .items {
      > .item:last-child {
        margin-bottom: 30px;
      }

      > .item {
        position: relative;
        width: 100%;
        color: #7a7a7a;
        font-size: 18px;

        > .flex {
          display: flex;
          align-items: flex-start;

          > .content {
            flex: 1;

            > .info {
              display: flex;

              .qty {
                margin-right: 10px;
                flex: 0 0 auto;
              }
              .name {
                color: #6e6e6e;
                line-height: normal;
              }
            }

            > .attributes {
              margin: 15px;
              margin-left: 0;
            }
          }
          > .remove {
            cursor: pointer;
          }
        }
      }
    }
    > .attributes {
      margin: 15px;
      margin-left: 0;
    }
  }
  .desk {
    background-color: #7a7a7a;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 0 15px;
    color: white;

    &.inversedRow {
      flex-direction: row-reverse;
    }

    .price {
      font-size: 20px;
    }
    .date {
      display: flex;
      align-items: center;
      font-size: 15px;

      .md-icon {
        color: white !important;
        margin-right: 5px;
      }
    }
  }

  hr.dotted {
    border-top: 1px dotted #efeded;
    margin-top: 0;
    margin-bottom: 1em;
  }
}
</style>
