import sanitize from 'sanitize-filename'
export default function createCalendarEvent(title, startDate, endDate) {
  if (!endDate) endDate = startDate

  const icsFileContent = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
SUMMARY:${title}
DTSTART:${startDate.toISOString().replace(/-|:|\.\d+/g, '')}
DTEND:${endDate.toISOString().replace(/-|:|\.\d+/g, '')}
END:VEVENT
END:VCALENDAR`

  const dataUri = `data:text/calendar;charset=utf-8,${encodeURIComponent(
    icsFileContent,
  )}`
  const fileName = sanitize(title) + '.ics'

  const link = document.createElement('a')
  link.href = dataUri
  link.download = fileName
  link.click()
}
